.App {
    height: 100vh;
}

.heightMoreButton {
    height: 20px !important;
}

.tableContainer {
    flex-basis: 125px;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
}

.tableStickyContainer {
    position: relative;
    height: 96%;
    display: flex;
    flex-direction: column;
}

.paginationContainer {
    width: 100%;
    margin-top: var(--margin-sixteen);
    background-color: #fff;
}
.modalContainer {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0px 0px 0px 0px;
    background-color: rgba(0, 0, 0, 0.295);
    z-index: 40;
}

.neoModal {
    max-width: 600px;
    max-height: 300px;
}

.neoShimmers {
    margin-bottom: var(--margin-sixteen);
}

.uploadButtonWrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.fileInput {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    cursor: pointer;
}

.closeIcon {
    font-size: var(--font-size-eighteen);
    margin-left: var(--margin-eight);
}

.bottomMargin {
    margin-bottom: var(--margin-sixteen);
}

.marginTop {
    margin-top: var(--margin-sixteen);
}

.spanStyle {
    display: inline-block;
    font-weight: var(--font-weight-400) !important;
}

.lableStyleOne {
    font-weight: var(--font-weight-600) !important;
    font-size: var(--font-size-fourteen) !important;
    line-height: var(--line-height-20) !important;
}

.lableStyleTwo {
    font-weight: var(--font-weight-400) !important;
    font-size: var(--font-size-twelve) !important;
    line-height: var(--line-height-20) !important;
}

a {
    text-decoration: none;
}

.scrollableContainer {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow: hidden;
    flex-basis: 0;
    overflow: auto;
    
}

.createUpdateContactListFormContainer {
    background-color: #fff;
    padding: var(--padding-sixteen);
    height: 100%;
    display: flex;
    flex-direction: column;
}

/*sheet style*/

.sheetContainer {
    position: fixed;
    min-width: 33.33%;
    top: 0;
    right: 0;
    height: 100vh;
}

.sheetMessage > p {
    font-weight: var(--font-weight-400);
    font-size: var(--font-size-fourteen);
    line-height: var(--line-height-20);
    padding: 0px;
    margin: 0px;
}

.sheetContent {
  overflow-y: auto;
  scrollbar-width: thin !important;
  overflow-x: hidden;
}

.neo-table__actions {
    justify-content: flex-start !important;
}

.neo-table tr {
    border-bottom: 1px solid #f1f1f1 !important;
}

.noDataFoundContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: var(--margin-eight);
    width: 100%;
    border-radius: 5px;
    height: calc(100% - 52px);
    background-color: var(--primary-grey);
}

.noDataFoundContainer > h1 {
    color: var(--primary-white);
    font-size: 2rem;
    font-weight: var(--font-weight-600);
}

.neo-modal__body {
    margin-bottom: var(--margin-thirty);
}

.textAlignRight {
  text-align: right;
}