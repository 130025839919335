.dashboardContainer {
    display: grid;
    grid-template-rows: 58px auto;
    background-color: var(--primary-grey);
    height: 100%;
}

.bodyContainer {
    display: grid;
    grid-template-columns: 240px auto;
    background-color: var(--primary-grey);
    margin-bottom: var(--padding-eight);
}
