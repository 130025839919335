* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.Toastify__toast-icon {
    margin-right: 20px !important;
}

.Toastify__close-button {
    position: absolute;
    top: 50%;
    right: 3%;
    transform: translate(-50%, -50%);
}

.Toastify__close-button > svg {
    color: #000;
}

:root {
    /* Colors */
    --primary-white: #FFF;
    --primary-grey: #E5E5E5;
    --primary-red: #D93D32;
    --secondary-grey: #a8a8a8;

    /* Padding */
    --padding-eight: 8px;
    --padding-sixteen: 16px;

    /* Margin */
    --margin-eight: 8px;
    --margin-sixteen: 16px;
    --margin-twenty: 20px;
    --margin-thirty: 30px;

    /* Font Size */
    --font-size-twelve: 12px;
    --font-size-fourteen: 14px;
    --font-size-sixteen: 16px;
    --font-size-eighteen: 18px;

    --font-weight-600: 600;
    --font-weight-500: 500;
    --font-weight-400: 400;

    --line-height-20: 20px;

    --toastify-color-info: #cfe7f7 !important;
    --toastify-color-success: #E4F7E4 !important;
    --toastify-color-warning: #f7f0d7 !important;
    --toastify-color-error: #fcdbd7 !important;

    --toastify-text-color-info: #000 !important;
    --toastify-text-color-success: #000 !important;
    --toastify-text-color-warning: #000 !important;
    --toastify-text-color-error: #000 !important;
}
