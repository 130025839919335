.spinnerContainer {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 50%;
    z-index: 9999;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ffffffd6;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}