.topNavigationContainer {
    background-color: var(--primary-white);
    width: 100%;
    height: 58px;
}

.neo-dropdown__content > .userName  {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    font-weight: var(--font-weight-500) !important;
}

.neo-dropdown__content > .userName > span > p + p  {
    font-size: var(--font-size-twelve);
    color: var(--secondary-grey);
}

#logout {
    color: var(--primary-red);
}

#logout:hover {
    color: var(--primary-red) !important;
}
