.landingContainer {
    display: grid;
    grid-template-columns: auto 60%;
    background-color: #FFF;
}

.contentContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-left: 70px;
}

.contentContainer > a:hover:not([class*=disabled]) {
    text-decoration: none;
}

.messageContainer {
    display: block;
    margin-bottom: 48px;
}

.messageContainer > h4 {
    font-weight: var(--font-weight-400);
    font-size: 26px;
    line-height: 40px;
}