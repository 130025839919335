.subscriptionContainer {
    background-color: #fff;
    padding: var(--padding-sixteen);
    height: 100%;
    display: flex;
    flex-direction: column;
}

.breadcrumbContainer {
    display: flex;
}

.breadcrumb {
    width: calc(100% - 220px);
}

.nameLabelStyle {
    font-size: var(--font-size-fourteen);
}

.floatRight {
    float: right;
}

.buttonContainer {
    display: flex;
}

.exportButton {
    height: 38px;
    margin-top: 16px;
    margin-left: 8px;
}
